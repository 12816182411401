<script lang="tsx">
import { VNode, h } from 'vue';
import { InputPayload, ChangePayload } from '~/types/solo-input';
import { generateId } from '~/util/generateId';
import { InputTypes, InputTypesCustom } from '~/types/step';

export default {
  props: {
    defaultValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    emitValidation: {
      type: String,
      default: undefined,
    },
    emitBlur: {
      type: String,
      default: undefined,
    },
    emitChange: {
      type: String,
      default: undefined,
    },
    emitInput: {
      type: String,
      default: 'input',
    },
    filterValidation: {
      type: RegExp,
      default: null,
    },
    for: {
      type: String,
      default: undefined,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator: (value: string) => {
        const allowedValues = ['left', 'right'];
        return allowedValues.includes(value);
      },
    },
    inputAttrs: {
      type: Object,
      default: null,
    },
    notValidOverride: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String as () => InputTypesCustom,
      default: 'text',
      validator: (value: string) => {
        const allowedValues = [
          'text',
          'email',
          'phone',
          'password',
          'birthday',
          'date',
          'textarea',
          'select-basic',
          'select',
        ];
        return allowedValues.includes(value);
      },
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array as () => string[],
      default: undefined,
    },
    placeholder: {
      type: String,
      default: 'Placeholder',
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: (String as () => InputTypes) || null,
      default: null,
      validator: (value: string) => {
        const allowedValues = [
          'button',
          'checkbox',
          'color',
          'date',
          'datetime-local',
          'email',
          'file',
          'hidden',
          'image',
          'month',
          'number',
          'password',
          'radio',
          'range',
          'reset',
          'search',
          'submit',
          'tel',
          'text',
          'time',
          'url',
          'week',
        ];
        return allowedValues.includes(value);
      },
    },
  },
  setup(props, { slots, emit, expose }) {
    const svgNames: any = {
      ErrorLineSvg: 'error-warning-fill',
      TextLineSvg: 'edit-line',
      EmailLineSvg: 'mail-line',
      PhoneLineSvg: 'phone-line',
      PasswordLineSvg: 'lock-line',
      BirthdayLineSvg: 'calendar-event-line',
      SelectLineSvg: 'book-2-line',
    };

    const state = reactive({
      classArray: ['solo-input-parent'],
      value: props.defaultValue,
      notValid: props.notValidOverride,
      checked: false,
    });

    const computedClass = computed(() => {
      const newClassArr = [];
      newClassArr.push(state.notValid ? 'input-danger' : '', `icon-input-${props.iconPosition}`);
      return state.classArray.concat(newClassArr);
    });

    const compId = computed(() => generateId('in'));

    const cType = computed(() => {
      return props.type ? props.type : variantToType();
    });

    watch(
      () => props.notValidOverride,
      (newValue) => {
        state.notValid = newValue;
      }
    );

    const component = getCurrentInstance();

    const inputValidation = () => {
      if (props.emitValidation) {
        const payload = {
          inputValue: state.value,
          element: component?.vnode,
        };
        emit(props.emitValidation, payload);
      }
    };
    const handleBlur = () => {
      if (props.emitBlur) emit(props.emitBlur);
    };
    function clearInput(): void {
      const target: HTMLInputElement | HTMLTextAreaElement | null =
        component?.vnode?.el?.querySelector('input') || component?.vnode?.el?.querySelector('textarea');
      if (target) target.value = props.defaultValue;
      const payload = {
        target,
      };
      if (target) handleInput(payload as any);
    }
    const filterChars = (value: string): string => {
      const newValue = value.match(props.filterValidation);
      return newValue?.join('') || '';
    };
    const handleChange = (e: InputEvent) => {
      const target = e.target as HTMLInputElement;

      let payload: InputPayload = {
        name: target.name,
        value: target.value,
      };

      if (cType.value === 'checkbox' || cType.value === 'radio') {
        payload = {
          ...payload,
          checked: target.checked,
        } as ChangePayload;
        state.checked = target.checked;
      } else {
        state.value = target.value;
      }

      if (props.emitChange) emit(props.emitChange, payload);
    };
    const handleInput = (e: InputEvent) => {
      const target = e.target as HTMLInputElement;
      const value = target.value;
      const payload = {
        value,
        name: target.name,
      };
      if (cType.value === 'tel' && !props.filterValidation) payload.value = simplePhoneValidation(payload.value);
      if (props.filterValidation) payload.value = filterChars(payload.value);

      target.value = payload.value;
      state.value = payload.value;
      emit(props.emitInput, payload);
    };
    const simplePhoneValidation = (value: string): string => {
      const allowedValues = /[/()0-9+. -]/g;
      const newValue = value.match(allowedValues);
      return newValue?.join('') || '';
    };
    const variantToType = (): string => {
      const nonStandard = {
        phone: 'tel',
        birthday: 'date',
        'select-basic': 'text',
      };
      let returnType: any = props.variant;

      if (Object.keys(nonStandard).includes(props.variant))
        returnType = nonStandard[props.variant as keyof typeof nonStandard];
      return returnType;
    };

    expose({
      clearInput,
    });

    const render = () => {
      const renderOptions = (): VNode[] => {
        const parsedOptions: VNode[] = [];
        const optionsLayout = props.options ? props.options : ['Option 1', 'Option 2', 'Option 3'];

        if (props.variant === 'select')
          parsedOptions.push(
            <option class="select-placeholder" value="">
              {props.placeholder}
            </option>
          );

        optionsLayout.forEach((option) => {
          if (option === '') return;
          parsedOptions.push(<option value={option.trim()}>{option.trim()}</option>);
        });

        return parsedOptions;
      };

      const inputTemplate = (): VNode => {
        const changeTypes = ['select', 'checkbox', 'radio'];

        const data: Record<string, any> = {
          id: compId.value + '-input',
          name: props.name,
          placeholder: props.placeholder,
          type: cType.value,
          value: state.value,
          disabled: props.disabled,
          required: props.required,
          ...(props.inputAttrs && props.inputAttrs),
          onBlur: [inputValidation, handleBlur],
          class: ['solo-input', { 'invalid-input': state.notValid }],
        };

        let tagName = 'input';
        let children = [] as VNode[];

        if (changeTypes.includes(cType.value)) {
          data.onChange = handleChange;
        } else {
          data.onInput = handleInput;
        }

        if (props.variant === 'select-basic') {
          tagName = 'input';
          children = [<datalist id={compId.value + '-dropdown'}>{() => renderOptions()}</datalist>];
          data.list = compId.value + '-dropdown';
        }

        if (cType.value === 'text') return h(tagName, data, () => children);

        if (cType.value === 'select') {
          tagName = 'select';
          children = slots.selectCustom ? slots.selectCustom() : renderOptions();
        }

        if (cType.value === 'textarea') {
          tagName = 'textarea';
        }

        return h(tagName, data, () => children);
      };
      const svgGrabber = (variant: string, override = false): VNode | null => {
        if (variant.includes('select')) variant = 'select';
        if (variant === 'textarea') variant = 'text';
        if (variant === 'date') variant = 'birthday';
        variant = variant.charAt(0).toUpperCase() + variant.slice(1) + 'LineSvg';

        const svgIcon = h('SvgTemplate', { 'svg-name': svgNames[variant] });

        return props.hasIcon || override ? svgIcon : null;
      };

      const inputChildren = [
        slots.icon ? slots.icon() : svgGrabber(props.variant),
        <label class="solo-label" for={props.for ? props.for : compId.value + '-input'}>
          {{
            default: () => [
              slots.default ? slots.default() : '',
              slots.extraElements ? slots.extraElements() : null,
              inputTemplate(),
            ],
          }}
        </label>,
      ];

      const directChildren = {
        default: () => [
          <div class={computedClass.value}>
            {{
              default: () => (props.iconPosition === 'right' ? inputChildren.reverse() : inputChildren),
            }}
          </div>,
          state.notValid ? (
            <div class="input-txt-danger">
              {svgGrabber('error', true)}
              {slots.errorMessage ? slots.errorMessage() : 'This is an error message for an input.'}
            </div>
          ) : null,
        ],
      };
      const componentData = {
        class: ['input-component-container', { 'invalid-input': state.notValid }],
      };

      return h('div', componentData, directChildren);
    };

    return render;
  },
};
</script>

<style lang="scss" scoped>
.input-component-container {
  box-sizing: border-box;
}

.solo-label {
  color: inherit;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
}

.solo-input {
  border: none;
  font-size: 0.875rem;
  height: 1.5rem;
  outline: none;
  padding: 0;
  width: 100%;

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

textarea.solo-input {
  min-height: 30px;
  max-width: 100%;
  min-width: 100%;
}

.input-txt-danger {
  align-items: center;
  color: $color-danger-dark;
  display: flex;
  fill: $color-danger-dark;
  justify-content: flex-start;
  margin: 0.5rem 0;

  :deep(svg) {
    height: 1rem;
    width: 1rem;
    margin-right: 4px;
  }
}

.solo-input-parent {
  align-items: center;
  background: $color-neutral-white;
  border: 2px solid $color-neutral-cool-700;
  border-radius: 4px;
  color: $color-neutral-cool-900;
  display: flex;
  padding: #{local-functions.rem-calc(8px 12px)};

  &:hover {
    border: 2px solid $color-neutral-black;
  }

  &.input-danger {
    border: 2px solid $color-danger-default;
    color: $color-danger-dark;
    fill: $color-danger-dark;

    &:hover {
      border: 2px solid $color-danger-dark;
    }

    &:focus-within {
      .solo-input {
        color: $color-neutral-cool-900;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .solo-input {
      color: inherit;
    }
  }

  &:focus-within {
    border: 2px solid $color-secondary-500;
    color: $color-secondary-500;
    fill: $color-secondary-500;

    /* stylelint-disable-next-line no-descending-specificity */
    .solo-input {
      &::placeholder {
        opacity: 0;
      }
    }

    &:hover {
      border: 2px solid $color-secondary-500;
      color: $color-secondary-500;
      fill: $color-secondary-500;
    }
  }

  &.icon-input-left {
    :deep(svg) {
      margin-right: 1rem;
    }
  }

  &.icon-input-right {
    :deep(svg) {
      margin-left: 1rem;
    }
  }
}
</style>
